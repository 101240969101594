/* Hide scrollbar for Chrome, Safari and Opera */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
/* Add these lines to your global.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
/* src/styles/global.css or create a new CSS file */
.rounded-rectangular-image {
  width: 100px; /* Adjust width as needed */
  height: 400px; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 75px; /* Creates a rounded rectangular shape */
  overflow: hidden;
  transition: transform 0.3s ease;
}

.rounded-rectangular-image:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  display: block;
  animation: marquee 10s linear infinite;
  width: 100%; /* Ensure it takes full width */
}

.custom-arrow {
  font-size: 24px; /* Adjust the size as needed */
  top: 50%; /* Center the arrows vertically */
  transform: translateY(-50%); /* Ensure the arrows are centered */
  z-index: 2; /* Ensure the arrows are above the content */
  color: var(--arrow-color); /* Use the custom property for color */
}

/* Ensure the arrows have the correct color in light and dark modes */
:root {
  --arrow-color: black;
}

body.dark {
  --arrow-color: gray;
}

.text-border {
  position: relative;
  display: inline-block;
}

.text-border::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  color: black;
  -webkit-text-stroke: 1px black;
  text-stroke: 1px black;
}
