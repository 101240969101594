.carousel-wrapper {
    overflow: hidden;
  }
  
  .carousel-item {
    position: relative;
    transform-origin: center center;
    transition: transform 0.5s ease-in-out;
    border-radius: 15px;
    overflow: hidden;
  }
  
  .slick-center .carousel-item {
    transform: scale(1.3);
    z-index: 10;
  }
  
  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    background: rgba(255, 255, 255, 0.8);
  }
  
  /* src/styles/carousel.css */
.carousel-item-center-sponsor {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 767px) {
  .custom-arrow {
    display: none !important;
  }
}


/* src/styles/carousel.css */
.scrolling-wrapper {
  display: flex;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.scrolling-content {
  display: flex;
  animation: scroll 40s linear infinite;
}

.scrolling-item {
  display: inline-block;
  padding: 0 1rem;
  /* height: 100px; Ensure consistent height */
  /* line-height: 100px; Center the logos vertically */
}

.scrolling-item img {
  max-height: 100%; /* Ensure logos fit within the container */
  width: auto; /* Maintain aspect ratio */
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
